<template>
    <div class="text-center">
    </div>
</template>

<script>
export default {
    name: "Home",
    mounted() {
        this.$vuetify.rtl = true;
    },    
    data: () => ({
        colors: ["green", "purple", "orange", "indigo", "red"],
        rating: 0,
        cookieFilled: "$vuetify.icons.tovi_cookie_filled",
        cookieOutline: "$vuetify.icons.tovi_cookie_outline",
        
    }),
    methods: {
        genColor(i) {
            return this.colors[i];
        },
    },
};
</script>
