<template>
    <v-app>
        <v-app-bar app color="orange" dark>
            <div class="d-flex align-center">
                <v-img
                    alt="לוגו"
                    class="shrink mr-2"
                    contain
                    src="./assets/logo-white.svg"
                    transition="scale-transition"
                    width="40"
                />
            </div>
            <div class="logo-text">מבשלים היי-טק</div>
            <v-spacer></v-spacer>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",
    mounted() {
        this.$vuetify.rtl = true;
        console.log(this.$router);
    },
    data: () => ({
        //
    }),
    watch: {
        $route(to, from) {
            console.log(to);
            console.log("---");
            console.log(from);
        },
    },
};
</script>
