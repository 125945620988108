<template>
    <div class="vote">
        <template v-if="!notFound && !thankYou">
            <template v-if="recipe != null">
                <v-row align="center">
                    <v-col align="center" style="font-size: 20pt; font-family: logoFont">
                        דרג את: {{ recipe.name }}
                    </v-col>
                </v-row>
                <v-row align="center" style="height: 30px"></v-row>
                <v-row align="center" dense style="margin: 10px">
                    <!-- <v-col cols="1"></v-col> -->
                    <v-col cols="12" align="center" dense>
                        <v-rating v-model="rating" background-color="grey darken-1" x-large >
                            <template v-slot:item="props">
                                <v-icon size="70" :color="props.isFilled ? 'orange' : 'grey lighten-1'" @click="props.click">
                                    {{ props.isFilled ? cookieFilled : cookieOutline }}
                                </v-icon>
                            </template>
                        </v-rating>
                    </v-col>
                    <!-- <v-col cols="1"></v-col> -->
                </v-row>
                <v-row align="center" dense style="margin: 10px">
                    <v-col cols="4" align="center" dense style="font-size: 14pt; font-family: logoFont">בא לי למות</v-col>            
                    <v-col cols="4" align="center" dense>
                    </v-col>
                    <v-col cols="4" align="center" dense  style="font-size: 14pt; font-family: logoFont">טעים למות</v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="12" align="center" style="font-size: 20pt; font-family: logoFont">
                        <v-btn  @click="rate()" :disabled="rating == 0" color="success" large rounded style="font-size: 20pt">
                            שלח
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row align="center" style="margin: -20px" v-if="error != ''">
                    <v-col cols="12" align="center" style="font-size: 20pt; font-family: logoFont; color: orange">
                        {{ error }}
                    </v-col>
                </v-row>
            </template>
        </template>

        <template v-if="thankYou">
            <v-row align="center">
                <v-col align="center" style="font-size: 24pt; font-family: logoFont">
                    תודה על הדירוג!
                    <br>
                    <v-img style="border-radius: 20px; margin: 20px" contain src="../assets/Cookie-monster-gif.gif"></v-img>
                </v-col>
            </v-row>
        </template>        

        <template v-if="notFound">
            <v-row align="center">
                <v-col align="center" style="font-size: 24pt; font-family: logoFont">
                    דף לא נמצא
                    <br>
                    <v-avatar size="120">
                        <img src="../assets/sad-cookie-monster.png">
                    </v-avatar>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Recipe",
    mounted() {
        var self = this;
        this.$vuetify.rtl = true;
        this.recipeId = this.$route.params.id;
        this.$nextTick(() => {
            self.getRecipe();
            console.log("####");
            if ($cookies.get(self.recipeId) != null) {
                self.thankYou = true;
            }
            console.log("####");
        });
        
    },    
    data: () => ({
        recipeId: "",
        rating: 0,
        recipe: null,
        cookieFilled: "$vuetify.icons.tovi_cookie_filled",
        cookieOutline: "$vuetify.icons.tovi_cookie_outline",
        notFound: false,
        thankYou: false,
        error: "",
    }),
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
        }),
    },
    methods: {
        rate() {
            var self = this;
            
            var options = {
                url:  `${this.currentDomain}/RATE_RECIPE`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: { recipe: this.$route.params.id, score: this.rating }
            };
            console.log(this.$route.params.id);
            this.$http(options).then((response) => {            
                console.log(this.$route.params.id)
                console.log(response.status);
                if (response.status == 200) {
                    self.thankYou = true;
                    self.error = "";
                    $cookies.set(self.$route.params.id, self.$route.params.id, 60 * 60 * 24 * 365);
                } else {
                    self.error = "חלה שגיאה במהלך הדירוג";
                    console.error("Cannot rate recipe");
                }
            });
        },
        getRecipe() {
            var self = this;
            
            var options = {
                url:  `${this.currentDomain}/GET_RECIPE`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                params: { id: this.$route.params.id }
            };
            console.log(this.$route.params.id);
            this.$http(options).then((response) => {            
                console.log(this.$route.params.id)
                console.log(response.status);
                if (response.status == 200) {
                    self.recipe = response.body;
                    
                } else {
                    self.notFound = true;
                    console.error("Cannot get recipe");
                }
            });
        },        
    },
};
</script>
